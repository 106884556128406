import { useEffect } from 'react';
import Lottie from 'react-lottie';
import { useStateMachine } from 'little-state-machine';
import { useRouter } from 'next/router';
import useHasMount from '../../hooks/utils/useHasMount';
import * as animationData from './PageLoader.json';

// Global state
import { updateNavigation, updateSession } from '../../../store/actions';

// Hooks
import useRouterEvents from '../../hooks/navigation/useRouterEvents';

// Styles
import { LoaderWrapper } from './PageLoaderStyles';

export default function PageLoader() {
  const {
    actions,
    state: { navigation, session, labels },
  } = useStateMachine({ updateNavigation, updateSession });

  const router = useRouter();

  const isMounted = useHasMount();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    renderer: 'canvas',
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  function handleStart() {
    actions.updateNavigation({
      pageLoading: true,
    });
  }

  function handleStop() {
    actions.updateNavigation({ pageLoading: false });
  }

  useRouterEvents({ handleStart, handleStop });

  useEffect(() => {
    if (router.asPath.includes('/register') && session?.signInProgress) {
      actions.updateSession({ signInProgress: false });
    }
  }, [router]);

  if (!isMounted) return null;
  if (navigation?.pageLoading || session?.signInProgress) {
    return (
      <LoaderWrapper>
        <div id="lottie" data-testid="lottie">
          <Lottie options={defaultOptions} />
        </div>

        {labels?.loadingLabel && <p>{labels?.loadingLabel}</p>}
      </LoaderWrapper>
    );
  }

  return null;
}
